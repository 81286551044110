import { ErrorMessage, Field, FieldAttributes } from "formik"
import "./input-field.css"
import * as React from "react"

type InputFieldProps = {
  label?: string
} & React.HTMLProps<HTMLInputElement>

const InputField = (props: FieldAttributes<InputFieldProps>) => (
  <div id={"input-field"}>
    <label htmlFor={`#${props.name}`}>{props.label}</label>
    <Field className="form-control" id={props.name} {...props} />
    <ErrorMessage
      name={props.name}
      render={msg => (
        <div
          style={{
            color: "red",
            margin: "0 0 15px 0",
            fontWeight: 400,
            fontSize: "13px",
          }}
        >
          {msg}
        </div>
      )}
    />
  </div>
)

export default InputField
